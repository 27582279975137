/*.===============================
#2.2 tp-counter-area
===============================*/
.tp-counter-area,
.tp-counter-style-2 {
    padding: 100px 0;
    position: relative;
    background: #fff;
}

.tp-counter-area .tp-counter-grids .grid,
.tp-counter-style-2 .tp-counter-grids .grid {
    width: 24%;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    position: relative;
    padding: 70px 0;
    background: #f8f8f8;
}

.tp-counter-area .tp-counter-grids .grid p,
.tp-counter-style-2 .tp-counter-grids .grid p {
    font-size: 18px;
    font-weight: normal;
    color: #999;
    padding-top: 5px;
    margin-bottom: 0;
}

.tp-counter-area .tp-counter-grids,
.tp-counter-style-2 .tp-counter-grids {
    overflow: hidden;
    position: relative;
}

.tp-counter-area .odometer.odometer-auto-theme,
.tp-counter-area .odometer.odometer-theme-default,
.tp-counter-style-2 .odometer.odometer-auto-theme,
.tp-counter-style-2 .odometer.odometer-theme-default {
    line-height: 0.8em;
}

.tp-counter-area .tp-counter-grids .grid h2,
.tp-counter-style-2 .tp-counter-grids .grid h2 {
    font-size: 60px;
    font-weight: 600;
    color: #212122;
    margin: 0;
    line-height: 10px;
    margin-top: 8px;
    padding-bottom: 35px;
}

@media(max-width: 1200px) {

    .tp-counter-area .tp-counter-grids .grid,
    .tp-counter-style-2 .tp-counter-grids .grid {
        width: 23%;
    }
}

@media(max-width: 992px) {

    .tp-counter-area .tp-counter-grids .grid h2,
    .tp-counter-style-2 .tp-counter-grids .grid h2 {
        font-size: 35px;
    }

    .tp-counter-area .tp-counter-grids .grid,
    .tp-counter-style-2 .tp-counter-grids .grid {
        width: 31.33%;
    }
}

@media(max-width: 767px) {

    .tp-counter-area .tp-counter-grids .grid,
    .tp-counter-style-2 .tp-counter-grids .grid {
        width: 47.33%;
        padding: 30px 0;

    }
}

@media(max-width: 450px) {

    .tp-counter-area .tp-counter-grids .grid,
    .tp-counter-style-2 .tp-counter-grids .grid {
        width: 100%;

    }
}
