.amenity-area {
    margin-top: 10px;
    margin-bottom: 20px;
}

.card-body {
    vertical-align: middle;
    padding-top: 1.25em !important;
    padding-bottom: .5em !important;
    padding-right: 1.25em !important;
    padding-left: 1.25em !important;
}


.ammenities-wrapper {
    margin-left: 55px;
    margin-right: 55px;
}

.card-title {
    /* color:#ffc039; */
        color:#03b664;
        /* color:#ffc039; */

}

.fa-stack-1x .fa {
    /* color: white; */
    color:#03b664;
    /* color:#ffc03983; */
}

.fa-stack-2x {
    color:#ffc039;
    /* color:#03b664; */
}

  /* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .card {
        text-align: center;
    }
}

/* // Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}

/* // Medium devices (tablets, less than 992px) */
/* @media (max-width: 991.98px) {} */