.container {
    padding-left: 5px;
    padding-right:5px;
}

.logoBreak {
    flex-basis: 100%;
    width: 0;
  }

.logoName {
    color: #18b760;
    font-size: 27px;
    font-weight: 700;
    padding-bottom: 0px;
    margin-bottom: -8px;
    font-family: 'Open Sans', sans-serif!important;
}


// #18b760 - green
// #FFC039 - orange1
// #e9bf3d - orange2

.logoSlogan {
    color: #FFC039;
    font-size: 18px;
    font-weight: 600;
    padding-top: 0px;
    margin-top: 0px;
    font-family: 'Open Sans', sans-serif!important;
    // font-family: 'Oswald', sans-serif !important;
}

.logoText {
    margin-top: 10px;
    padding-top: 0px;
    padding-left: 7px;
    display: inline-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-left: solid 5px #FFC039;
}

.logo a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: flex-start;
    /* vertical-align: text-top; */
    /* flex-wrap: wrap; */
}

.logo img {
    width: 75px;
    height: 75px;
    margin-top: 5px;
}

.logo a img {
    margin-right: 10px;
}

.logo {
    margin-top: 20px;
    margin-bottom: 2px;
    flex-wrap: nowrap;
}

.logo a:hover {
    color: #212122;
}

 /* // X-Small devices (portrait phones, less than 576px) */
 @media (min-width: 0PX) and (max-width: 576px) {
     .logo {
         margin-top:0px;
     }
    .logo img {
        max-width: 60px;
        max-height: 60px;
    }
    .logoName {
        padding-top:5px;
        font-size: 17px;
    }
    .logoSlogan {
        font-size: 15px;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
    .logo img {
        max-width: 65px;
        max-height: 65px;
    }
    .logoName {
        font-size: 25px;
    }
    .logoSlogan {
        font-size: 20px;
    }
 }

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
    .logoName {
        font-size: 25px;
    }
    .logoSlogan {
        font-size: 20px;
    }
 }

// Medium devices (tablets, 768px to 991.98px)
@media (min-width: 768px) and (max-width: 991.98px) { 
    .logoName {
        font-size: 23px;
    }
    .logoSlogan {
        font-size: 20px;
        margin-top: -3px;
    }
    .logo {
        margin-top: 15px;
        margin-bottom: 5px;
    }
    .logoText {
        margin-top:10px;
    }
 }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199.98px) { ... }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }