.phone-1, .phone-2 {
    border-right: 1px solid #dfe2e6;
    display: inline-flex;
}

.donations-btn {
    height: 100px;
}

.topbar {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.topbar {
    border-bottom: 1px solid #dfe2e6;
    background: #fff;
}

/* @media (max-width: 991px) {
    .topbar {
        text-align: center;
        padding: 0;
    }
} */

div > .innerContainer {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .phone-1, .phone-2 {
        border-right: 0px solid #dfe2e6;
        text-align: center;
    }
    .topbar {
        text-align: center;
        padding: 20px 0;
    }
    div > .innerContainer {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.topbar p {
    font-size: 15px;
    color: #636363;
    margin: 0;
}

@media (max-width: 991px) {
    .topbar p {
        margin-bottom: 8px;
        font-size: 18px;
    }
}

.innerContainer {
    overflow: hidden;
    list-style: none;
	/* float: right; */
	margin-bottom: 0;
}

@media (max-width: 991px) {
    .innerContainer {
        float: none;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.topbar {
    font-size: 18px;
    /* float: left; */
    /* margin: 7px 7px; */
    color: #fff;
    line-height: 1em;
    border-right: 1px solid rgba(255, 255, 255, .1);
}

.topbar .contact-info a {
    color: #666;
}

.topbar .contact-info a:hover {
    color: #7db1f0;
}

.topbar {
    color: #666;
    border-right: 1px solid #dfe2e6;
}

.topbar:first-child {
    padding-left: 0;
}

.topbar .contact-intro .innerContainer {
    /* float: none; */
}

@media (max-width: 991px) {
    .topbar {
        font-size: 18px;
    }
    .topbar .theme-btn {
        margin-top: 2px;
    }
}

@media (max-width: 991px) {
    .topbar {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .topbar {
        /* float: left; */
        border: none;
        padding: 8px 6px;
        font-size: 16px;
    }
}

@media (max-width: 991px) {
    .topbar {
        text-align: center;
    }

    .wpo-header-style-2 .topbar .contact-info,
    .topbar .contact-info {
        /* float: left; */
    }
}

.topbar .innerContainer>div+div+div+div {
    border-right: none;
    padding-right: 10px;
    padding: 0;
}

.topbar .innterContainer>div+div+div {
    border-right: none;
}

.innerContainer>div+div {
    /* border-right: none; */
}

.topbar .theme-btn {
    padding: 12px 20px;
    margin-top: 2px;
    font-size: 18px;
    font-weight: 600;
    color: #fff !important;
}

.innerContainer .fi {
    position: relative;
    top: 1px;
    display: inline-block;
    padding-right: 3px;
}

.innerContainer .fi:before {
    font-size: 15px;
    font-size: 1rem;
    color: #fff;
}

.innerContainer .fi:before {
    color: #666;
}

@media (max-width: 991px) {
    .innerContainer .fi:before {
        font-size: 20px;
        font-size: 1.33333rem;
    }
}

@media (max-width: 767px) {
    .innerContainer .fi:before {
        font-size: 16px;
    }
}


.navigation .innerContainer {
    list-style: none;
}