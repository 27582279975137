/*====================================
#0.8 Header
=====================================*/

.navbar-flex {

    display: flex;
    justify-content: space-between;

    // display: inline-flex;
    // flex-flow: row wrap;
    // justify-content: space-around;
    // align-items: stretch;
    flex-wrap: nowrap;
}

.nav-main {
    // align-self: flex-start;
    flex-grow: 1;
}

.nav-items {
    flex: 0 1 1000px;
    // flex-grow: 3;
    // align-self: flex-end;
}

.nav-logo {
    margin-bottom: 15px;
}


/* // Large devices (desktops, less than 1200px) */
/* @media (max-width: 1199.98px) {  } */
/* // X-Large devices (large desktops, less than 1400px) */
/* @media (max-width: 1399.98px) {  } */

.mobile-menu {
    margin-right: auto;
}
.middle-header {
    box-shadow: 0px 5px 14.25px 0.75px rgba(12, 105, 184, 0.1);
}

/* Navigation */

nav ul {
    margin-top: -4px;
    text-align: right;
}

nav>ul>li {
    display: inline-block;
    margin-right: -1px;
    position: relative;
}

nav ul li a {
    display: inline-block;
    color: #666;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    padding: 40px 25px;
}

nav ul li a:before {
    content: "";
    background: rgba(255, 255, 255, 0.1);
    width: 100%;
    height: 43px;
    border-radius: 43px;
    position: absolute;
    left: 0;
    top: calc(50% - 21px);
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    z-index: -1;
}

nav ul li a.active:before {
    opacity: 1;
}

nav ul li a:hover:before {
    opacity: 1;
}

nav ul li:hover>ul {
    opacity: 1;
    visibility: visible;
}

nav ul li:hover>ul li {
    margin-top: 0;
}

nav ul li a:hover,
nav ul li a.active {
    color: #18b760;
    text-decoration: none;
}

nav ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 215px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    text-align: left;
}

nav ul ul li {
    padding: 10px 15px;
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
    margin-top: 20px;
    position: relative;
    transition: all .5s;
    position: relative;
}

nav ul ul li i {
    position: absolute;
    right: 10px;
    top: 18px;
}

nav ul ul li a {
    padding: 5px;
    color: #333;
}

nav ul ul li:last-child {
    border-bottom: 0;
}

nav ul ul ul {
    left: 100%;
    top: 4px;
    transition: all .5s;
}

header.fixed,
header.fixed.style2,
.layout3 header.fixed,
.layout4 header.fixed,
header.ab.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);
    padding-top: 0;
}

header.fixed .header-content {
    -webkit-box-shadow: inherit;
    -moz-box-shadow: inherit;
    -ms-box-shadow: inherit;
    -o-box-shadow: inherit;
    box-shadow: inherit;
    padding-left: 0;
    padding-right: 0;
}

.layout3 header.fixed {
    background-color: #0a15a9;
}

.middle-header nav ul {
    margin-bottom: 0;
}

.header-content .contact-info-1 span {
    font-size: 16px;
    color: #fff;
    display: inline-block;
    margin-right: 45px;
}

@media(max-width:1200px) {
    .contact .theme-btn-s2 {
        display: none;
    }
}

@media(max-width:991px) {
    .middle-header {
        position: relative;
        background: #fff;
    }
    .header-content .contact-info-1 span {
        margin-right: 20px;
        margin-top: 0;
    }
    .logo {
        margin-top: 15px;
        padding-bottom: 10px;
    }
}

@media(max-width:500px) {
    .header-content .contact-info-1 span {
        display: none;
    }
}

.header-style-2 .mini-cart:hover .mini-cart-content,
.header-style-3 .mini-cart:hover .mini-cart-content,
.header-style-2 .header-search-form-wrapper:hover .header-search-form,
.header-style-3 .header-search-form-wrapper:hover .header-search-form {
    opacity: 1;
    visibility: visible;
    right: 0;
}

@media(max-width:1400px) {
    nav ul ul ul {
        left: -100%;
    }
}

@media(max-width:1200px) {
    nav ul li a {
        display: inline-block;
        padding: 40px 20px;
    }
}