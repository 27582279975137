.container h4{
  color: #ffc039;
  font-weight: bold;
  font-size: 30px;
}
.container .subheading{
  color: #03b664;
  font-size: 20px;
}

.cape-img {
  padding-top: 15px;
  padding-bottom: 15px;
}

p {
    /* font-weight: 200; */
    /* margin-bottom: 100px; */
    font-size: 1.5rem;
    font-weight: 300;
    padding-right: 25px;
    padding-left: 25px;
  }

  .about-wrapper {
      margin-right: 40px;
      margin-left: 40px;
      font-size: 1.5rem;
      font-weight: 300;

  }

.about-centered {
  text-align: center;
}

  @media (min-width: 993px){
    .cape-img {
      display: block;
      padding-top: 5px;
      padding-bottom: 15px;
    }
    
  }


@media (min-width: 0px) and (max-width: 993px){
    .about-wrapper {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 5px;
      margin-right: 5px;
    }
    .container {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }