.section-container > div {
  align-items: center;
}

.section-container {
    /* margin-top: -150px; */
    margin: 0px 50px 30px 50px;
    padding: 1%;
    text-align: center;
}
.section-container h4{
    color: #ffc039;
    font-weight: bold;
    font-size: 30px;
}
.section-container .subheading{
    color: #03b664;
    font-size: 20px;
}

.program-img {
  width: 250px;
}

  /* // X-Small devices (portrait phones, less than 576px) */
  /* @media (max-width: 575.98px) {} */
  
  /* // Small devices (landscape phones, less than 768px) */
  @media (max-width: 767.98px) {
    .section-container > div {
      margin-top: 20px !important;
    }
    .program-img {
      width: 200px;
    }
  }
  
  /* // Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .section-container {
      margin-top: 0px;
    }
  }