/*=======================================
#0.9 contact-cta-area
=========================================*/

.contact-cta-area{
    background: url(../../images/cta.jpg) no-repeat right center;
    padding: 90px 0;
  }
  .contact-cta-area .row{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
  }
  .contact-cta-left h2 {
      font-size: 36px;
      color: #fff;
      font-weight: 600;
      text-align: center;
      padding-right: 30px;
      margin: 0;
      line-height: 45px;
      margin-bottom: 10px;
  }
  
  .contact-cta-wrap{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .contact-cta-call span{
    font-size: 34px;
    font-weight: 600;
    color: #fff;
  }
  .contact-cta-call h3 {
      font-size: 40px;
      color: #ffc039;
      margin-top: 0;
  }
  .contact-cta-call:last-child h3{
    font-size: 40px;
    /* color: #03b664; */
    color: #ffc039;
  }

  .contact-cta-green {
    color: #03b664;
  }
  .contact-cta-orange {
    color: #ffc039;
  }
  
  @media(max-width: 1200px){
    .contact-cta-call h3 {
        font-size: 28px;
        margin-top: 10px;
    }
    .contact-cta-call:last-child h3 {
        font-size: 28px;
        margin-top: 10px;
    }
    .contact-cta-left h2 {
        font-size: 30px;
    }
    .contact-cta-call span {
      font-size: 23px;
      color: #fff;
  }
  }
  @media(max-width: 991px){
    .contact-cta-area .row{
      display: unset;
    }
    .contact-cta-wrap {
      justify-content: center;
      margin-top: 30px;
      flex-wrap: wrap;
  }
  .contact-cta-call{
    padding: 0 20px;
  }

  
  .contact-cta-area {
      background-size: cover;
  
  }
  .contact-cta-left h2 {
      padding-right: 0;
  }
  }
  
  @media(max-width: 767px){
    .contact-cta-area {
      padding: 40px 0;
    }
  .contact-cta-left h2 {
      font-size: 26px;
      padding-right: 0;
  }
  }